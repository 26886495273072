<template>
  <div class='salary-container'>
    <b-row>
     <b-col lg="12" class="my-1">
       <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Filter by Country"
            />

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-table striped hover :items="sortedCountryDetails" :fields="fields" :filter="filter" :filter-included-fields="filterOn">
      <template #cell(issue)="data">
        <a v-if="data.value" :href="data.value">Link</a>
      </template>
    </b-table>
  </div>
</template>

<script>
  // eslint-disable-next-line filenames/match-regex
  export default {
    data() {
      return {
        sourceData: {},
        countryDetails: [],
        fields: [
          {
            key: 'country',
            sortable: true
          },
          {
            key: 'issue',
            sortable: false
          },
          {
            key: 'detail',
            sortable: false
          },
          {
            key: 'canHire',
            sortable: true
          },
          {
            key: 'hiringRestrictions',
            sortable: true
          }
        ],
        filter: null,
        filterOn: ['country']
      }
    },
    computed: {
      sortedCountryDetails() {
        return this.sortArrayByCountry(this.countryDetails)
      }
    },

    mounted () {
      this.getSourceData();
    },
    methods: {
      getSourceData () {
        fetch('/hiring_status/data.json')
          .then(response => response.json())
          .then(data => this.setData(data))
          .catch((error) => { console.error(error) })
      },
      setData(data) {
        this.sourceData = data

        data.countriesData.forEach((countryData) => {
          const element = {
            country: countryData.country,
            issue: countryData.issue,
            detail: countryData.detail,
            canHire: (countryData.hiring && !countryData.sanction) || false,
            hiringRestrictions: countryData.explanation
          }
          this.countryDetails.push(element)
        })
      },
      sortArrayByCountry(array) {
        return array.sort((countryDetailOne, countryDetailTwo) => {
          if (countryDetailOne.country < countryDetailTwo.country) {
            return -1;
          }
          if (countryDetailOne.country > countryDetailTwo.country) {
            return 1;
          }
          return 0
        })
      },
    }
  }
</script>