// eslint-disable-next-line filenames/match-regex
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import TurbolinksAdapter from 'vue-turbolinks';

import Vue from 'vue';
import CountryStatus from '../hiring/CountryStatus.vue'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('country-status');

  if (el !== null) {
    // eslint-disable-next-line no-new
    new Vue({
      el,
      render: h => h(CountryStatus, {
        props: {
        }
      }),
    });
  }
});